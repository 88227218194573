import axios from 'axios';
import React, { useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import Swal from 'sweetalert2';
import { CREATE_SCAN_LOG_MUTATION } from '../gql/scanLog';

const QrScanner = () => {
  const [qrscan, setQrscan] = useState<string>('');
  const [startScan, setStartScan] = useState<boolean>(true);
  const [scanError, setScanError] = useState<any>('');

  const userID = localStorage.getItem('myUserID');

  const handleScan = async (qrData: any) => {
    if (qrData) {
      setQrscan(qrData);
      // setStartScan(false);

      if (qrscan !== '' && startScan) {
        setStartScan(false);
        try {
          const response = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_QR_API_KEY}/${qrscan}`,
          });

          if (response.data.status === 'success') {
            setStartScan(false);
            Swal.fire({
              title: 'Success',
              icon: 'success',
              html: `
              <p>Visitor Details</p>
              <br />
              <p>Name: ${response.data.first_name} ${
                response.data.last_name
              }</p>
              <p>Position: ${
                response.data.company_role ? response.data.company_role : 'NA'
              }</p>
              <p>Company Name: ${
                response.data.company_name ? response.data.company_name : 'NA'
              }</p>
              `,
              confirmButtonText: 'Confirm',
            }).then((result) => {
              if (result.isConfirmed) {
                setQrscan('');
                setStartScan(true);
              }
            });

            axios
              .post(process.env.REACT_APP_API_KEY!, {
                query: CREATE_SCAN_LOG_MUTATION,
                variables: {
                  uid: userID,
                  visitorId: qrData,
                  status: response.data.status,
                  message: response.data.message,
                  firstName: response.data.first_name,
                  lastName: response.data.last_name,
                  companyName: response.data.company_name,
                  companyRole: response.data.company_role,
                },
              })
              .then(async (data) => {
                console.log(data);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } catch (error) {
          if (error && startScan) {
            setStartScan(false);
            setScanError(error);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (scanError) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        html: `${scanError.response.data.error}`,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          setQrscan('');
          setStartScan(true);
        }
      });
    }
  }, [scanError]);

  const handleError = (err: any) => {
    console.log(err);
  };

  return (
    <div>
      <>
        <div style={{ marginBottom: '1rem' }}>
          <QrReader
            facingMode={'environment'}
            delay={1500}
            onError={handleError}
            onScan={handleScan}
            style={{ height: '80%', width: '100%' }}
          />
        </div>
      </>
    </div>
  );
};

export default QrScanner;
