export const LOGIN_ACCESS_PASS_MUTATION = `
  mutation loginAccessPass(
    $uid: String!,
    $password: String!
    ) {
    loginAccessPass(
      uid: $uid
      password: $password
    )
  }
`;
