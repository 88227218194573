import { ReactNode, createContext, useState } from 'react';
import { AuthValuesType, ErrCallbackType, LoginParams } from './types';
import axios from 'axios';
import { LOGIN_ACCESS_PASS_MUTATION } from '../gql/authentication';

const defaultProvider: AuthValuesType = {
  loading: true,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);

  const handleLogin = (
    params: LoginParams,
    errorCallback?: ErrCallbackType,
  ) => {
    axios
      .post(process.env.REACT_APP_API_KEY!, {
        query: LOGIN_ACCESS_PASS_MUTATION,
        variables: params,
      })
      .then(async (data) => {
        if (data.data.errors) {
          errorCallback && errorCallback(data.data.errors[0].message!);
        } else {
          localStorage.setItem('myToken', data.data.data.loginAccessPass);
          errorCallback && errorCallback({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        if (errorCallback) errorCallback(err);
      });
  };

  const handleLogout = () => {
    window.localStorage.removeItem('myToken');
    window.localStorage.removeItem('myUserID');
  };

  const values = {
    loading,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
