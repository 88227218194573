import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Card,
  FormControl,
  FormHelperText,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Icon,
  Button,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { useAuth } from '../hooks/useAuth';
import logo from '../assets/logo.png';

const useStyles = makeStyles({
  card: {
    width: '100%',
    maxWidth: 400,
    p: '1rem',
    textAlign: 'center',
  },
  form: {
    marginBottom: '1rem',
  },
  error: {
    color: 'red',
  },
});

const defaultValues = {
  uid: '',
  password: '',
};

interface FormData {
  uid: string;
  password: string;
}

interface Props {
  isLoading: any;
  setIsLoading: any;
}

const Login = ({ isLoading, setIsLoading }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [buttonLoading, setIsButtonLoading] = useState<boolean>(false);
  const classes = useStyles();
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  const auth = useAuth();

  const onSubmit = async (data: FormData) => {
    const { uid, password } = data;
    localStorage.setItem('myUserID', uid);
    setIsLoading(true);
    setIsButtonLoading(true);
    try {
      await auth.login({ uid, password }, (bool) => {
        setError('uid', {
          type: 'manual',
          message: 'User ID or Password is invalid',
        });
        setError('password', {
          type: 'manual',
          message: 'User ID or Password is invalid',
        });

        setIsButtonLoading(false);

        if (bool) {
          setIsLoading(bool);
        }
      });
    } catch (error) {
      console.log(error);
      setIsButtonLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        px: 2,
        py: 8,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card className={classes.card}>
        <img
          src={logo}
          alt={'afex-logo'}
          style={{ height: '140px', width: 'auto', marginTop: '1rem' }}
        />
        <Box sx={{ m: 4 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth className={classes.form}>
              <Controller
                name="uid"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    variant="outlined"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={Boolean(errors.uid)}
                    placeholder="Enter User ID"
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth className={classes.form}>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    placeholder="Enter Password"
                    onChange={onChange}
                    id="auth-login-v2-password"
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={(e: any) => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Icon>
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
            </FormControl>

            {errors.uid && (
              <FormHelperText className={classes.error}>
                {errors.uid.message}
              </FormHelperText>
            )}

            <Button
              disabled={buttonLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{
                backgroundColor: '#3F7DB2',
                color: '#fff',
                margin: '1rem auto',
              }}
            >
              {buttonLoading ? 'Loading' : 'Login'}
            </Button>
          </form>
        </Box>
      </Card>
    </Box>
  );
};

export default Login;
