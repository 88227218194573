export const CREATE_SCAN_LOG_MUTATION = `
mutation CreateScanLog($uid: String!, $status: String!, $message: String!, $firstName: String, $lastName: String, $companyName: String, $companyRole: String, $visitorId: String!) {
  createScanLog(uid: $uid, status: $status, message: $message, first_name: $firstName, last_name: $lastName, company_name: $companyName, company_role: $companyRole, visitor_id: $visitorId) {
    _id
    company_name
    company_role
    first_name
    last_name
    message
    status
    uid
    visitor_id
  }
}
`;
